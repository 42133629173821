<template>
  <div class="labor-contract edit-wrapper">
    <div class="form-content-wrapper">
      <div class="form-content">
        <el-form ref="examDataForm" :model="examData" :rules="formRules" label-width="120px" size="medium">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="staffName">
                <el-select  v-model="examData.jobNumber" filterable @change="handleSelect" :disabled="examData.status==3" placeholder="输入姓名进行搜索">
                  <el-option v-for="item in staffs"
                             :key="item.jobNumber"
                             :label="item.staffName"
                             :value="item.jobNumber">
                    <span title="工号">{{item.jobNumber}}</span>
                    <span title="姓名">{{'('+item.staffName+') '}}</span>
                    <br>
                    <span class="auto-complete-idcard" title="身份证号">{{item.idCard}}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idCard">
                <el-input maxlength="18" v-model="examData.idCard" :disabled="examData.status==3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="本次合同主体" prop="contractsPartId">
                <el-select v-model="examData.contractsPartId"  @change="changeContracts">
                  <el-option v-for="itr in Contracts" :key="itr.value" :label="itr.text" :value="itr.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="聘用部门" prop="deptId">
                <department :checkStrictly="true" v-model="examData.deptId"></department>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="聘用岗位" prop="positionIds">
                <position :checkStrictly="true" v-model="examData.positionIds"></position>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签订类别" prop="contractType">
                <el-radio-group  v-model="examData.contractType" @change="changeConType">
                  <el-radio :label="1">首签</el-radio>
                  <el-radio :label="2">续签</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="首入中粮时间" prop="firstContractTime">
                <date v-model="examData.firstContractTime" :disabled="examData.contractType==1"></date>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="原合同主体" prop="contractsPartOriginal">
                <el-input v-model="examData.contractsPartOriginal"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="变动原因" prop="changeReasonId">
                <el-select v-model="examData.changeReasonId">
                  <el-option v-for="reason in ChangeReasons" :key="reason.id" :label="reason.changeReason" :value="reason.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同期限" prop="contractsTerm">
                <el-input v-model="examData.contractsTerm"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="合同开始日期" prop="contractsBeginTime">
                <date v-model="examData.contractsBeginTime"></date>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同结束日期" prop="contractEndTime">
                <date v-model="examData.contractEndTime"></date>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="试用期" prop="isTrialPeriod">
                <el-radio-group v-model="examData.isTrialPeriod" @change="changeTrail">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="试用期开始日期" prop="beginTrailTime">
                <date v-model="examData.beginTrailTime" :disabled="!examData.isTrialPeriod"></date>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="试用期结束日期" prop="endTrailTime">
                <date v-model="examData.endTrailTime" :disabled="!examData.isTrialPeriod"></date>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审批类型" prop="activityKeyValue">
                <el-select v-model="examData.activityKeyValue" @change="getFlowModelByApType" :disabled="examData.status==3">
                  <el-option v-for="itr in ApprovalTypes" :key="itr.activityKeyValue" :label="itr.name" :value="itr.activityKeyValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider class="line-dividing"><span>流程图</span></el-divider>
        <div class="diagram-content">
            <flow :flows.sync="bpmActivityModel" :status="examData.status"></flow>
        </div>
      </div>
    </div>
    <div class="occupy-ele"></div>
    <div class="button-group fixed-bottom-btn">
      <el-button class="btn-default" size="medium" @click="back">返回</el-button>
      <el-button class="btn-reset mgl20" size="medium" @click="resetForm" v-if="examData.status==6">重置</el-button>
      <el-button class="btn-submit mgl20" size="medium" type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import Department from "../../../../components/department";
import Position from "../../../../components/position.vue";
import Date from "../../../../components/date.vue";
import {validateIDcard} from "../../../../utils/validator";
import Flow from "../../../../components/flow";
import DetailFlow from "../../../../components/detail-flow";
import {validateApprover} from "../../../../utils/utils";
export default {
  name: "labor-contract-edit",
  components:{DetailFlow, Flow, Position, Department,Date},
  data(){
    return {
      timer:null,
      isEdited:false,
      examData:{
        contractType:1,
        isTrialPeriod:true,
        activityKeyValue: '',
        id:'',
      }, // 表单对象
      bpmActivityModel:[], // 流程节点
      Contracts:[], // 劳动合同主体数据list
      pickerOptions:{
        disabledDate(time){
          return time.getTime() > Date.now()
        }
      },
      ChangeReasons:[], // 变动原因数据list
      ApprovalTypes:[], // 审批类型
      formRules:{},
      timeRules:{
        beginTrailTime:[
          this.$formValidator.empty("试用期开始日期",'change')
        ],
        endTrailTime:[
          this.$formValidator.empty("试用期结束日期",'change')
        ]
      },
      flows:[],
      typeId:this.$store.state.PERSONNEL.LABOR_CONTRACT_TYPEID,
      staffs:[]
    }
  },
  props:['id','taskId'],
  watch:{
    examData:{
      handler:function(newVal,oldVal){
        if(newVal.id&&oldVal.id!==''){
          this.isEdited=true;
          if(newVal.status!=3){
            if(this.timer){
              clearTimeout(this.timer)
            }
            this.timer = setTimeout( this.updateDraft, 500)
          }
        }
      },
      deep:true
    }
  },

  created() {
    // 初始化select list
    this.getContracts();
    this.getChangeReasons();
    this.getStaffs();
    this.init();
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods:{
    async init(){
      await this.getApprovalTypes();
      if (this.$route.params.id||this.id) {  // 驳回待修改编辑
        this.getDetail(this.$route.params.id||this.id)
      }else{
        this.queryDraft()
      }
    },
    initRules(){
      this.formRules = {
        staffName:[
            this.$formValidator.empty("用户",'change')
        ],
        idCard:[
          {required:true,message:'请输入身份证号',trigger:'change'},
          {validator:validateIDcard}
        ],
        contractsPartId:[
          this.$formValidator.empty("本次合同主体",'change', "number")
        ],
        deptId:[
          this.$formValidator.empty("聘用部门",'change','number')
        ],
        positionIds:[
          {required:true,message:'请选择聘用岗位',trigger:'change'},
        ],
        contractsPartOriginal:[
          this.$formValidator.empty("原合同主体")
        ],
        changeReasonId:[
          this.$formValidator.empty("变动原因",'change',"number")
        ],
        contractsTerm:[
          this.$formValidator.empty("合同期限")
        ],
        contractsBeginTime:[
          this.$formValidator.empty("合同开始日期",'change')
        ],
        contractEndTime:[
          this.$formValidator.empty("合同结束日期",'change')
        ],
        activityKeyValue:[
          this.$formValidator.empty("审批类型",'change','number')
        ]
      }
      if(this.examData.contractType===2){
        Object.assign(this.formRules,{
          firstContractTime:[
            this.$formValidator.empty("首入中粮时间",'change')
          ]
        })
      }
      if(this.examData.isTrialPeriod){
        Object.assign(this.formRules,this.timeRules)
      }
    },
    getContracts(){
      this.$api.personnel.getContractsList({pageNum:1,pageSize:10000}).then(response=>{
        this.Contracts = response.data;
      })
    },
    changeContracts(e){
      this.examData.contractsPartA = this.Contracts.filter(itr=>itr.value==e)[0].text
    },
    getChangeReasons(){
      this.$api.personnel.getChangeReasons({}).then(response=>{
        this.ChangeReasons = response.data.list;
      })
    },
    getStaffs(){
      let param = {
        staffName:'',
        pageSize:10000,
        pageNum:1
      }
      this.$api.personnel.staffRosterList(param).then(res=>{
        this.staffs  = res.data.list;
      })
    },
    changeConType(){
      if(this.examData.contractType===1){
        this.examData.firstContractTime = '';
        if(this.formRules.firstContractTime){
          this.$refs.examDataForm.clearValidate(['firstContractTime'])
          delete this.formRules.firstContractTime;
        }
      }else{
        if(!this.formRules.firstContractTime&&Object.keys(this.formRules).length){
          Object.assign(this.formRules,{
            firstContractTime:[
              this.$formValidator.empty("首入中粮时间",'change')
            ]
          })
        }
      }
    },
    changeTrail(){
      if(!this.examData.isTrialPeriod){
        this.examData.beginTrailTime = '';
        this.examData.endTrailTime = '';
        if(this.formRules.beginTrailTime){
          delete this.formRules.beginTrailTime;
          delete this.formRules.endTrailTime;
          this.$refs.examDataForm.clearValidate(['beginTrailTime']);
          this.$refs.examDataForm.clearValidate(['endTrailTime']);
        }
      }else{
        if(!this.formRules.beginTrailTime&&Object.keys(this.formRules).length){
          Object.assign(this.formRules,this.timeRules);
        }
      }
    },
    getApprovalTypes(){
      this.$api.personnel.getApprovalType({type:this.typeId}).then(response=>{
       this.ApprovalTypes = response.data.list
      })
    },

    // 重置
    async resetForm(){
      let data = {
        contractType:1,
        isTrialPeriod:true,
        activityKeyValue: this.ApprovalTypes[0].activityKeyValue,
        id:this.examData.id,
        status:6
      }
      this.$api.personnel.reset({typeId:this.typeId}).then(res=>{
        this.examData=data;
        this.getFlowModelByApType()
      })
    },
    handleSelect(e){
      let select = this.staffs.filter((item=>{
        return item && item.jobNumber==e
      }))[0]
      this.examData.staffName = select.staffName;
      this.$set(this.examData,'idCard',select.idCard)
      this.examData.deptId = parseInt(select.deptId);
      this.examData.positionIds = select.positionIds;
      this.examData.staffRosterId = select.id;
    },

    // 返回
    back(){
      if(this.id){
        this.$emit('changeType',  true)
      }else{
        this.$router.replace('/businessModule/personnel/labor-contract')
      }
    },

    // 查询草稿
    queryDraft(){
      this.$api.personnel.queryLaborCtraDraft({}).then(res=>{
        if(res.data&&res.data.id){ // 如果有草稿 就编辑草稿
          this.examData = res.data;
          if(!this.examData.activityKeyValue){
            this.examData.activityKeyValue = this.ApprovalTypes[0].activityKeyValue;
          }
          this.getFlowModelByApType();
        }else{ // 如果没有草稿 就新增草稿
          this.addDraft()
        }
      })
    },

    // 新增草稿
    addDraft(){
      this.examData.status=6;
      let param = JSON.parse(JSON.stringify(this.examData))
      this.$api.personnel.insertLaborCtraDraft(param).then(res=>{
        this.examData.id = res.data.id
        // 初始审批类型
        this.examData.activityKeyValue = this.ApprovalTypes[0].activityKeyValue
        this.getFlowModelByApType();
      })
    },

    // 更新草稿
    updateDraft(){
      // 如果用户已经点击了提交按钮，这个时候应该return;
      if(this.examData.status==3){
        return ;
      }
      let param =JSON.parse(JSON.stringify(this.examData))
      this.$api.personnel.updateLaborCtraDraft(param).then(res=>{

      })
    },

    submit(){
      // 驳回待修改状态的数据 必须修改表单之后才能提交
      if(!this.isEdited&&this.examData.status==3){
        return this.$message.error('表单内容未修改，请修改后提交')
      }
      this.initRules();
      this.$nextTick(()=>{
        this.$refs.examDataForm.validate(valid=>{
          if(valid){
            if(this.examData.contractsBeginTime>this.examData.contractEndTime){
              return this.$message.error('合同开始日期不能大于结束日期')
            }
            if(this.examData.beginTrailTime&&this.examData.endTrailTime){
              if(this.examData.beginTrailTime>this.examData.endTrailTime){
                return this.$message.error('试用期开始日期不能大于试用期结束日期')
              }
            }
            let node = JSON.parse(JSON.stringify(this.bpmActivityModel))
            if (!validateApprover(node)) {
              return;
            }
            let param = Object.assign({},this.examData)
            param.activityNodeDetails = node;
            param.typeId = this.typeId;
            let contract = this.Contracts.filter(itr=>itr.value==this.examData.contractsPartId);
            param.contractsPartA = contract.length?contract[0].text:'';

            let handler = this.examData.status==3?this.$api.personnel.reSubmitLaborCtra({
              "beginTrailTime": this.examData.beginTrailTime,
              "changeReasonId": this.examData.changeReasonId,
              "contractEndTime": this.examData.contractEndTime,
              "contractType": this.examData.contractType,
              "contractsBeginTime": this.examData.contractsBeginTime,
              "contractsPartA": param.contractsPartA,
              "contractsPartId": this.examData.contractsPartId,
              "contractsPartOriginal": this.examData.contractsPartOriginal,
              "contractsTerm": this.examData.contractsTerm,
              "deptId": this.examData.deptId,
              "endTrailTime": this.examData.endTrailTime,
              "firstContractTime": this.examData.firstContractTime,
              "id": this.examData.id,
              "isTrialPeriod": this.examData.isTrialPeriod,
              "positionIds": this.examData.positionIds
                }): this.$api.personnel.submitLaborCtra(param);
            handler.then(response=>{
              this.$message.success('提交成功')
              this.back()
            })
          }
        })
      })
    },

    // 根据审批类型获取流程模板
    getFlowModelByApType() {
      this.$api.personnel.bpmGetModelValue(this.examData.activityKeyValue).then((res) => {
        this.bpmActivityModel = []
        let data = (res.data.nodeList||[]).map((ele) => {
          ele.userList=ele.userList?ele.userList:[]
          return ele;
        });
        this.bpmActivityModel = data
      });
    },

  //  根据ID获取详情
    getDetail(id){
      this.$api.personnel.getCtraDataDetail({id:id}).then((res) => {
        this.examData = res.data;
        this.bpmActivityModel  = JSON.parse(JSON.stringify(res.data.activityModel.activitiNodeDetails));
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../common-style/edit-page";
</style>